import React from "react";
import { useLocation } from 'react-router-dom';

const SubmitPage = () => {
  const location = useLocation();
  const token = location.state?.token;

  return (
    <div className="bg">
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Ticket Raised Successfully!</h1>
      <span style={{ fontSize: "20px", }} >Your token number is <strong>{token || "N/A"}</strong></span>
    </div>
    </div>
  );
};

export default SubmitPage;