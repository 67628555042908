// Frontend developed by Dhinesh Kumar
// BAckend developed by Gowtham

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./Form.css";
import BASE_URL from "./Host";

const Form = () => {
  const [formData, setFormData] = useState({
    organizationName: "",
    personName: "",
    phoneNumber: "",
    email: "",
    department: "", 
    address: "",
    problemCategory: "",
    files: [], // Array of objects with file and name
    otherCategory: "",
    additionalInfo: "", // state for additional info text area
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const categoryOptions = {
    hardware: ["Computer", "Laptop", "CCTV", "Printers", "Billing Device"],
    software: ["Biometric", "Security Devices"],
    network: ["Firewall", "Server", "Storage", "Cloud"],
    automation: ["Smart Home", "IT Automation"],
    hrms: ["Manpower Supply", "Manpower"],
    electrical: ["Electrical", "UPS", "Inverter", "Solar"],
  };

  const validateField = (name, value) => {
    switch (name) {
      case "organizationName":
        return value ? "" : "The organization name field is required.";
      case "personName":
        return value ? "" : "The contact person name field is required.";
      case "phoneNumber":
        if (!value) return "The phone number field is required.";
        return /^\d{10}$/.test(value) ? "" : "Phone number must be 10 digits.";
      case "email":
        if (!value) return "The email field is required.";
        return /^\S+@\S+\.\S+$/.test(value) ? "" : "Invalid email format.";
      case "department":
        return value ? "" : "The department field is required.";
      case "address":
        return value ? "" : "The address field is required.";
      case "problemCategory":
        return value ? "" : "The problem category field is required.";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: validateField(name, value) }));
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      problemCategory: value,
      otherCategory: "",
    }));
    setErrors((prev) => ({
      ...prev,
      problemCategory: validateField("problemCategory", value),
    }));
  };

  const handleRadioChange = (e) => {
    setFormData((prev) => ({ ...prev, otherCategory: e.target.value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (formData.files.length + files.length > 5) {
      return toast.error("You can only upload up to 5 files.");
    }
    setFormData((prev) => ({
      ...prev,
      files: [
        ...prev.files,
        ...files.map((file) => ({ file, name: file.name })), // Store name along with file
      ],
    }));
  };

  const handleDeleteFile = (index) => {
    setFormData((prev) => {
      const updatedFiles = [...prev.files];
      updatedFiles.splice(index, 1);
      return { ...prev, files: updatedFiles };
    });
  };

  const handleImageNameChange = (index, e) => {
    const updatedFiles = [...formData.files];
    updatedFiles[index].name = e.target.value;
    setFormData((prev) => ({ ...prev, mark: updatedFiles }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (formData.files.length + files.length > 5) {
      return toast.error("You can only upload up to 5 files.");
    }
    setFormData((prev) => ({
      ...prev,
      files: [
        ...prev.files,
        ...files.map((file) => ({ file, name: file.name })), // Store name along with file
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate fields before submission
    const allErrors = {};
    Object.keys(formData).forEach((key) => {
      allErrors[key] = validateField(key, formData[key]);
    });
    setErrors(allErrors);
  
    if (Object.values(allErrors).some((error) => error)) return;
  
    setLoading(true);
  
    const submissionData = new FormData();
  
    // Append standard fields
    Object.keys(formData).forEach((key) => {
      if (key === "files") {
        // Handle files separately
        formData.files.forEach((fileObj, index) => {
            submissionData.append(`files[]`, fileObj.file); // Append file binary
            submissionData.append(`marks[]`, fileObj.name || ""); // Append mark as a flat array
        });
      } else {
        submissionData.append(key, formData[key]);
      }
    });
  
    try {
      const response = await axios.post(
        `${BASE_URL}api/index.php`,
        submissionData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        navigate("/submit", { state: { token: response.data.token } });
      } else {
        toast.error("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Submission failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-group">
        <label htmlFor="organizationName">Organization Name:</label>
        <input
          type="text"
          id="organizationName"
          name="organizationName"
          value={formData.organizationName}
          onChange={handleChange}
        />
        {errors.organizationName && <p className="error-text">{errors.organizationName}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="personName">Contact Person Name:</label>
        <input
          type="text"
          id="personName"
          name="personName"
          value={formData.personName}
          onChange={handleChange}
        />
        {errors.personName && <p className="error-text">{errors.personName}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        {errors.phoneNumber && <p className="error-text">{errors.phoneNumber}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="error-text">{errors.email}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="department">Department:</label>
        <input
          type="text"
          id="department"
          name="department"
          value={formData.department}
          onChange={handleChange}
        />
        {errors.department && <p className="error-text">{errors.department}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="address">Address:</label>
        <textarea
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
        ></textarea>
        {errors.address && <p className="error-text">{errors.address}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="problemCategory">Problem Category:</label>
        <select
          id="problemCategory"
          name="problemCategory"
          value={formData.problemCategory}
          onChange={handleCategoryChange}
        >
          <option value="">Select a category</option>
          {Object.keys(categoryOptions).map((key) => (
            <option key={key} value={key}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </option>
          ))}
          <option value="other">Other</option>
        </select>
        {errors.problemCategory && <p className="error-text">{errors.problemCategory}</p>}
      </div>

      {formData.problemCategory && (
        <div className="form-group">
          {categoryOptions[formData.problemCategory] && (
            <div className="radio-group">
              {categoryOptions[formData.problemCategory].map((option) => (
                <div key={option} className="radio-item">
                  <input
                    type="radio"
                    id={option}
                    name="otherCategory"
                    value={option}
                    checked={formData.otherCategory === option}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor={option}>{option}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="form-group">
        <label htmlFor="additionalInfo">Additional Information:</label>
        <textarea
          id="additionalInfo"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          placeholder="Provide any additional information here"
        ></textarea>
        {errors.additionalInfo && <p className="error-text">{errors.additionalInfo}</p>}
      </div>
      <div
        className="form-group drag-drop-container"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="drag-drop-box">
          <input
            type="file"
            id="files"
            name="files"
            accept=".jpg,.png"
            multiple
            onChange={handleFileChange}
            className="file-input"
          />
        </div>
        <p>Max 5 files. Allowed formats: .jpg, .png</p>
        {formData.files.length > 0 && (
          <div className="file-list">
            {formData.files.map((fileObj, index) => (
              <div key={index} className="file-item">
                <input
                  type="text"
                  value={fileObj.marks}
                  onChange={(e) => handleImageNameChange(index, e)}
                  placeholder="Enter query"
                  className="image-name-input"
                />
                <span>{fileObj.file.name}</span>
                <button type="button" onClick={() => handleDeleteFile(index)}>
                  Delete
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <button type="submit" className="btn-submit" disabled={loading}>
        {loading ? "Submitting..." : "Submit"}
      </button>

      <ToastContainer />
    </form>
  );
};

export default Form;